import styles from './Table.less';

import React, { useEffect, useState } from "react";
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react";
import { currencyFormatter } from '../../../../../utils/currency';
import { formatValue } from '../../Tools/ExportFunction';
import BigBetsMonthTableComponent from '../Tools/BigBetsMonthTableComponent';

interface MonthlyDataItem {
    Year: string;
    Months: { [month: string]: MonthlyComponent };
}

interface MonthlyComponent {
    Target: number;
    Current: number;
}

const maxWidthMonth = 125;

const columns: IColumn[] = [
    {
        key: 'year', name: 'Fiscal year', minWidth: 50, maxWidth: 70, isResizable: true, className: styles.PrimaryColumn,
    },
    {
        key: '0', name: 'January', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '1', name: 'February', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '2', name: 'March', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '3', name: 'April', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '4', name: 'May', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '5', name: 'June', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '6', name: 'July', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '7', name: 'August', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '8', name: 'September', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '9', name: 'October', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '10', name: 'November', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: '11', name: 'December', minWidth: 80, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumnDec,
    },
];

interface MonthlyRegionDetailsTableProps {
    Isloading: boolean;
    ActualData: [number, number][];
    TargetData: [number, number][];
    FirstDate: Date;
    DeliveryETA: Date;
}

const BigBetsMonthlyTable: React.FC<MonthlyRegionDetailsTableProps> = ({ Isloading, ActualData, TargetData, FirstDate, DeliveryETA }) => {
    //Get Columns
    const [newColumns, setNewColumns] = useState<IColumn[]>(columns || []);

    const [monthItem, setMonthItem] = useState<MonthlyDataItem[]>([]);

    useEffect(() => {
        if (Isloading) return;

        //Re-order Table Column
        const startMonth = FirstDate.getMonth();

        const reorderedMonthColumns = columns.slice(1) // get rid of 'Fiscal year'
            .sort((a, b) => {
                const monthA = parseInt(a.key);
                const monthB = parseInt(b.key);
                return (monthA + 12 - startMonth) % 12 - (monthB + 12 - startMonth) % 12;
            });

        const reorderedColumns = [columns[0], ...reorderedMonthColumns];
        setNewColumns(reorderedColumns);

        //month Item
        // Removed ETA pair
        const indexToRemove = TargetData.findIndex(item => item[0] === DeliveryETA.valueOf());

        const removedEtaTargetData = [...TargetData];
        // If found, remove the element from the array
        if (indexToRemove !== -1 && ActualData.findIndex(item => item[0] === DeliveryETA.valueOf()) === -1) {
            removedEtaTargetData.splice(indexToRemove, 1);
        }
        const monthItemTmp = calculateMonthlyDifferences(removedEtaTargetData, ActualData);
        setMonthItem(monthItemTmp);
    }, [Isloading, ActualData, TargetData, FirstDate]);

    function _renderItemColumn(item: MonthlyDataItem, index?: number, column?: IColumn): React.ReactNode {
        const year = item.Year;
        const months = item.Months;

        switch (column?.key) {
            case "year":
                return <span>{formatValue(year)}</span>;
        }
        if (months[column?.key as string]) {
            const component = months[column?.key as string];
            const gap = component.Current - component.Target;
            const gapTrend = 100 * gap / component.Target;
            return <BigBetsMonthTableComponent
                trendFlag={gap > 0}
                gap={currencyFormatter(gap, 2, (gap > 0 ? "+" : ""))}
                gapTrend={currencyFormatter(gapTrend, 2, (gap > 0 ? "+" : "")) + "%"}
                target={currencyFormatter(component.Target, 2, "")}
                current={currencyFormatter(component.Current, 2, "")}
            />
        }
    }

    return (
        <div>
            <DetailsList
                items={monthItem}
                setKey="set"
                compact={true}
                columns={newColumns}
                onRenderItemColumn={_renderItemColumn}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionZoneProps={{
                    disableAutoSelectOnInputElements: true,
                    isSelectedOnFocus: false
                }}
            />
        </div>
    );
}

function getLastOfMonthValues(arr: [number, number][]): Map<string, [number, number]> { //arr should be asc
    const lastOfMonthValues = new Map<string, [number, number]>();

    for (const pair of arr) {
        const monthYear = new Date(pair[0]).getFullYear() + '-' + new Date(pair[0]).getMonth();
        lastOfMonthValues.set(monthYear, pair);
    }

    return lastOfMonthValues;
}

function calculateMonthlyDifferences(targetpairs: [number, number][], currentPairs: [number, number][]): MonthlyDataItem[] {
    const monthlyData: MonthlyDataItem[] = [];
    const lastOfMonthValues = getLastOfMonthValues(currentPairs);

    const dataByYear: { [year: string]: MonthlyDataItem } = {};

    for (const pair1 of targetpairs) {
        const year = new Date(pair1[0]).getFullYear().toString();
        const month = new Date(pair1[0]).getMonth();
        const monthYear = year + '-' + month;

        if (!dataByYear[year]) {
            dataByYear[year] = {
                Year: year,
                Months: {},
            };
        }

        if (lastOfMonthValues.has(monthYear)) {
            const pair2 = lastOfMonthValues.get(monthYear)!;
            dataByYear[year].Months[month.toString()] = { Target: pair1[1], Current: pair2[1] };
        } else {
            dataByYear[year].Months[month.toString()] = { Target: pair1[1], Current: pair1[1] };
        }
    }

    const sortedYears = Object.keys(dataByYear).sort((a, b) => parseInt(a) - parseInt(b)); //asc
    for (const year of sortedYears) {
        monthlyData.push(dataByYear[year]);
    }

    return monthlyData;
}


export default BigBetsMonthlyTable;