import styles from './BigBetsDetails.less';

import React, { useEffect, useState } from 'react';
import { Separator } from '@fluentui/react';

import { currencyFormatter, formatNumber } from '../../../../utils/currency';
import { EFTrackerBigBetsProject, EFTrackerBigBetsProjectHistory } from '../../../../models/EfficiencyTrackerBigBets';
import { useEFTrackerBigBetsProjectHistory, useEFTrackerBigBetsReductionResult } from '../../../../hooks/useEfficiencyTrackerProject';
import BigBetsLinearChart from './BigBetsLinearChart';
import BigBetsConclusionBar from './Tools/BigBetsConclusionBar';
import BigBetsMonthlyTable from './Table/BigBetsMonthlyTable';
import BigBetsMonthlyProjectTable from './Table/BigBetsMonthlyProjectTable';

interface Props {
    data: EFTrackerBigBetsProject;
}

const BigBetsDetails: React.FC<Props> = (props) => {
    const project = props.data;

    const { data: historyResults, isLoading: isActualDataLoading } = useEFTrackerBigBetsReductionResult(project.ProjectID);
    const { data: historyProjects, isLoading: isloading } = useEFTrackerBigBetsProjectHistory(project.ProjectID);

    const [historyProject, setHistoryProject] = useState<EFTrackerBigBetsProjectHistory[]>([]);

    const [actualData, setActualData] = useState<[number, number][]>([]);
    const [targetData, setTargetData] = useState<[number, number][]>([]);
    const [gapData, setGapData] = useState<[number, number][]>([]);

    const [target, setTarget] = useState<number>(-1);
    const [current, setCurrent] = useState<number>(-1);
    const [gap, setGap] = useState<number>(0);
    const [trend, setTrend] = useState<number>(0); 
    const [chartMaxXAxis, setChartMaxXAxis] = useState<number>(new Date().valueOf()); 
    const [firstDate, setFirstDate] = useState<Date>(new Date());

    // Get project history
    useEffect(() => {
        if (!historyProjects || isloading || !historyProjects.length) return;

        setHistoryProject(historyProjects);
    }, [historyProjects, isloading]);
    //// Get ConsumptionData - setActualData & setCurrent
    useEffect(() => {
        if (!historyResults || isActualDataLoading || !historyResults.length) return;
        if (!isActualDataLoading) {
            const latestResult = historyResults.reduce((max, item) => {
                return item.Id > max.Id ? item : max;
            }, historyResults[0]);

            //Max pair time
            const lastDate = new Date(latestResult.DateValuePairs[latestResult.DateValuePairs.length - 1].PairDate);
            setChartMaxXAxis(Math.min(lastDate.setMonth(lastDate.getMonth() + 1).valueOf(), project.DeliveryETA.valueOf()));  // Set max xAxis to max(lastDate + 1month, deliveryETA)

            // First time
            const firstTime = latestResult.DateValuePairs[0].PairDate;
            setFirstDate(firstTime);

            // Target
            const targetTmp = latestResult.DateValuePairs
                .filter(pair => pair.Target !== null)
                .map(pair => [pair.PairDate.valueOf(), pair.Target || 0]);

            const addDeliveryETAData = [
                ...targetTmp,
                [project.DeliveryETA.valueOf(), project.ImpactDollar],
            ];

            //Actual
            const actualTmp = latestResult.DateValuePairs
                .filter(pair => pair.Actual !== null)
                .map(pair => [pair.PairDate?.valueOf(), pair.Actual || 0]);

            setActualData(actualTmp.map((pair) => {
                return [pair[0], pair[1]];
            }));
            setCurrent(actualTmp.length ? actualTmp[actualTmp.length - 1][1] : 0);

            //Gap & Interpolated Target
            const targetInterpolatedTmp: [number, number][] = addDeliveryETAData.map((pair) => {
                return [pair[0], pair[1]];
            })
            const targetTimes = addDeliveryETAData.map((i) => i[0]);
            const actualTimes = actualTmp.map((i) => i[0]);
            const gapDataTmp: [number, number][] = [];

            actualTimes.forEach(time => {
                let interpolatedValue;
                if (!targetTimes.find(target => target === time)) {
                    if (time > project.DeliveryETA.valueOf()) {
                        targetInterpolatedTmp.push([time, project.ImpactDollar]);
                        interpolatedValue = project.ImpactDollar;
                    } else {
                        const [prevTime, nextTime] = targetTimes.reduce(([prev, next], t) => {
                            if (t <= time && t > prev) return [t, next];
                            if (t >= time && t < next) return [prev, t];
                            return [prev, next];
                        }, [firstTime.valueOf(), project.DeliveryETA.valueOf()]);
                        const prevValue = targetInterpolatedTmp.find((i) => i[0] === prevTime)?.[1] || 0;
                        const nextValue = targetInterpolatedTmp.find((i) => i[0] === nextTime)?.[1] || 0;

                        interpolatedValue = prevValue + ((time - prevTime) / (nextTime - prevTime)) * (nextValue - prevValue);
                        targetInterpolatedTmp.push([time, interpolatedValue]);
                    }
                    
                }
                else {
                    interpolatedValue = addDeliveryETAData.find(target => target[0] === time)?.[1] as number;
                }
                gapDataTmp.push([time, ((actualTmp.find(data => data[0] === time)?.[1] as number) || 0) - interpolatedValue]);
            });

            targetInterpolatedTmp.sort(([timeA], [timeB]) => timeA - timeB);
            gapDataTmp.sort(([timeA], [timeB]) => timeA - timeB);

            if (gapDataTmp.length) {
                const latestTime = gapDataTmp[gapDataTmp.length - 1][0];
                setTarget(targetInterpolatedTmp.find(item => item[0] === latestTime)?.[1] || 0);
                setGap(gapDataTmp[gapDataTmp.length - 1][1] || 0);
            } else {
                setTarget(targetInterpolatedTmp[targetInterpolatedTmp.length - 1][1] || 0);
            }

            setTargetData(targetInterpolatedTmp);
            setGapData(gapDataTmp);
        }
    }, [historyResults, isActualDataLoading, project.DeliveryETA, project.ImpactDollar]);

    // SetGap
    useEffect(() => {
        setTrend(100 * gap / target);
    }, [gap, target]);

    return (
        <div>
            <Separator styles={{ root: styles.separator }} />
            <div className={styles.title}>
                Impact Trend ($)
            </div>
            {/*tmp hide */}
            <div style={{ display: 'none' }}> 
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '24px' }}>
                    <BigBetsConclusionBar title="Impact target" content={currencyFormatter(target, 2, "")} isTarget={true} />
                    <div style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="54" viewBox="0 0 16 54" fill="none">
                            <rect x="7" y="48" width="43" height="1" transform="rotate(-90 7 48)" fill="#C8C8C8" />
                        </svg>
                    </div>
                    <BigBetsConclusionBar title="Current Impact" content={currencyFormatter(current, 2, "")} trendFlag={gap < 0 ? true : false} isTarget={false} />
                    <div style={{ paddingLeft: '6px', paddingRight: '6px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="54" viewBox="0 0 16 54" fill="none">
                            <rect x="7" y="48" width="43" height="1" transform="rotate(-90 7 48)" fill="#C8C8C8" />
                        </svg>
                    </div>
                    <BigBetsConclusionBar title="Gap" content={currencyFormatter(gap, 2, "")} trendFlag={gap < 0 ? true : false} trend={(gap > 0 ? "+" : "") + formatNumber(trend) + "%"} />
                </div>

                <BigBetsLinearChart
                    isLoading={isActualDataLoading}
                    targetData={targetData}
                    actualData={actualData}
                    gapData={gapData}
                    chartMaxXAxis={chartMaxXAxis}
                />
            </div>
            <div style={{ display: 'none' }}>
                <BigBetsMonthlyTable

                    Isloading={isActualDataLoading}
                    ActualData={actualData}
                    TargetData={targetData}
                    FirstDate={firstDate}
                    DeliveryETA={project.DeliveryETA}
                />
            </div>
            {/*tmp hide end*/}
            <BigBetsMonthlyProjectTable
                Isloading={isActualDataLoading || isloading}
                ActualData={actualData}
                TargetData={targetData}
                FirstDate={firstDate}
                DeliveryETA={project.DeliveryETA}
                HistoryProject={historyProject}
                CurrentConfidence={project.ConfidenceInImpact}
                CurrentExecutionStatus={project.ExecutionStatus}
            />
        </div>
    );
};

export default BigBetsDetails;
