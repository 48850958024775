import { Dialog, DialogType, IComboBoxStyles } from "@fluentui/react";
import { FiltersAction, FiltersView } from "../../../reducers/filterReducer";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";

import { AsyncPaginate } from "react-select-async-paginate";
import { CategoryDivision } from "../../../models/CategoryDivision";
import { IAppState } from "../../../store";
import { MultiValue } from "react-select";
import { ServiceTreeBusinessOwners } from "../../../models/serviceTree";
import styles from "./ShareView.less";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useSelector } from "react-redux";
import { useServiceTreeBusinessOwnersQuery } from "../../../hooks/useServiceTreeQuery";
import { OptionType, getLoadOptions } from "../../EfficiencyTrackerV2/Views/Project/SelectUtils";

interface ShareViewServiceTreeDialog {
    updateStep: Dispatch<SetStateAction<number>>;
    onCancel: (viewType: string) => void;
}

const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 }, callout: { maxHeight: 200 } };

const ShareViewBusinessOwnerDialog: React.FC<ShareViewServiceTreeDialog> = (props) => {
    const { updateStep, onCancel } = props;
    const [selectedItemError, setSelectedItemError] = useState<boolean>(false);
    const businessOwners = useServiceTreeBusinessOwnersQuery();
    const businessOwnersMap = new Map<string, ServiceTreeBusinessOwners[]>();
    businessOwners.data?.forEach((item) => {
        const key = item.displayName ? item.displayName : item.userAlias;
        if (businessOwnersMap.has(key)) {
            businessOwnersMap.get(key)?.push(item);
        } else {
            businessOwnersMap.set(key, [item]);
        }
    });
    const dropDownOptions = [...businessOwnersMap.keys()].map((item) => ({ value: item, label: item }));

    const globalFilters = useCategoryFilters();
    const filtersNextAction = useSelector<IAppState, FiltersAction>((state) => state.filtersNextAction);

    const handleServicesChange = useCallback(
        (businessOwners: string[]) => {
            if (businessOwners.length) {
                const filters: Partial<Record<CategoryDivision, string[]>> = {};
                businessOwners.forEach((item) => {
                    if(!filters[CategoryDivision.BusinessOwner]) {
                        filters[CategoryDivision.BusinessOwner] = []
                    } 
                    filters[CategoryDivision.BusinessOwner].push(item);
                    const entitiesOfBusinessOwner = businessOwnersMap.get(item);
                    if (entitiesOfBusinessOwner) {
                        entitiesOfBusinessOwner.forEach((item) => {
                            const category = getServiceTreeCategory(item.entityLevel);
                            if (!filters[category]) {
                                filters[category] = [];
                            }
                            filters[category]?.push(item.entityId);
                        });
                    }
                });
                globalFilters.updateFilters(filtersNextAction, { filters, view: FiltersView.Summary });
                return filters;
            }
        },
        [filtersNextAction, globalFilters]
    );

    function getServiceTreeCategory(category: string) {
        switch (category) {
            case "Division":
                return CategoryDivision.Division;
            case "Organization":
                return CategoryDivision.Organization;
            case "ServiceGroup":
                return CategoryDivision.ServiceGroup;
            case "TeamGroup":
                return CategoryDivision.TeamGroup;
            default:
                return CategoryDivision.Service;
        }
    }

    const [selectedKeys, setSelectedKeys] = React.useState<string[]>((globalFilters.filters?.filters && globalFilters.filters?.filters[CategoryDivision.BusinessOwner]) ? globalFilters.filters?.filters[CategoryDivision.BusinessOwner] : []);
    const currentValue : MultiValue<OptionType> = selectedKeys.map(item => ({label:item, value:item}))
    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: "Create a new custom view",
        styles: {
            innerContent: {height: 400}
        }
    };

    const onShareViewCallout = function () {
        if (selectedKeys && selectedKeys.length > 0) {
            handleServicesChange(selectedKeys);
            updateStep(3);
        } else {
            setSelectedItemError(true)
        }
    };
    const handleRegionDropdownChange = (newValue: MultiValue<OptionType>) => {
        if(newValue) {
            if(newValue.length > 0) {
                setSelectedItemError(false)
            }
            const temp: React.SetStateAction<string[]> = [];
            [...newValue.values()].forEach(item => {
                temp.push(item.value)
            })
            setSelectedKeys(temp);
        }
    };

    return (
        <Dialog hidden={false} modalProps={{ containerClassName: styles.dialogContainer,  }} maxWidth={1000} dialogContentProps={dialogContentProps}>
            {/* <ComboBox
                multiSelect
                selectedKey={selectedKeys}
                label="Select leaders"
                autoComplete="on"
                options={dropDownOptions}
                onChange={onChange}
                styles={comboBoxStyles}
                calloutProps={{
                    styles: {
                        container: { maxHeight: 200, maxWidth: 200 },
                    },
                }}
            /> */}
            <div style={{marginBottom:10}}><label className={`${styles.labelWithAfter} ${selectedItemError ? styles.error : ''}`}>Select organization leaders.</label></div>
            <AsyncPaginate
                    isClearable
                    isSearchable
                    isMulti
                    name="Organization leaders"
                    placeholder="Select leaders"
                    value={currentValue}
                    loadOptions={getLoadOptions(dropDownOptions)}
                    onChange={handleRegionDropdownChange}
                    styles={{
                        multiValue: (base) => ({ ...base, maxWidth: 150 }),
                        container: (base) => ({ ...base, minWidth: 180 }),
                        menuList: (base) => ({ ...base }),
                        menu: (base) => ({ ...base }),
                    }}
                />
            <button
                className={styles.dialogCancelButton}
                onClick={() => {
                    updateStep(0);
                    onCancel("ServiceTree");
                }}
            >
                Cancel
            </button>
            <button className={styles.dialogButton} onClick={onShareViewCallout}>
                Next
            </button>
        </Dialog>
    );
};

export default ShareViewBusinessOwnerDialog;
