import { QueryClient, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "react-query";

/**
 * @template A The type of argument list.
 * @template R The actual return type of the query function
 * @param func The query function.
 * @param args The argument list of the query function.
 * @returns The query result.
 */
export function useCustomQuery<A extends any[], R>(func: QueryableFunction<A, Promise<R>>, ...args: A) {
    if (!func.queryKey) {
        throw new Error("useCustomQuery no query key found");
    }

    return useQuery<R, Error, R, [string, ...A]>([func.queryKey, ...args], (context) => {
        return func(...context.queryKey.slice(1) as A);
    }, func.options as any);
}

export type QueryableFunction<Args extends any[] = [], R = void> = ((...args: Args) => R) & {
    queryKey?: string;
    options?: UseQueryOptions;
};

export function Queryable<Args extends any[], R>(queryKey?: string) : any {
    return function(target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<(...args: Args) => R>) {
        (descriptor.value as QueryableFunction<Args, R>).queryKey = queryKey || propertyKey;

        return descriptor;
    };
}

export function QueryableClass<T extends { new(...args: any[]): any; }>(options?: UseQueryOptions) {
    return function(target: T) {
        Object.getOwnPropertyNames(target)
            .forEach(key => {
                const method = (target as any)[key];

                if (typeof method === "function") {
                    (method as QueryableFunction<any[], any>).queryKey = `${target.name}::${key}`;
                    (method as QueryableFunction<any[], any>).options = options;
                }
            });
    }
}

export interface CustomQueryClient {
    fetchQuery<A extends any[], R>(func: QueryableFunction<A, Promise<R>>, ...args: A) : Promise<R>;
    getQueryData<A extends any[], R>(func: QueryableFunction<A, Promise<R>>, ...args: A) : R;
    isFetching<A extends any[], R>(func: QueryableFunction<A, Promise<R>>, ...args: A) : number;
}

export function useCustomQueryClient() {
    const queryClient = useQueryClient();

    return {
        fetchQuery<A extends any[], R>(func: QueryableFunction<A, Promise<R>>, ...args: A) {
            return queryClient.fetchQuery([func.queryKey, ...args], (context) => {
                return func(...context.queryKey.slice(1) as A);
            });
        },
        getQueryData<A extends any[], R>(func: QueryableFunction<A, Promise<R>>, ...args: A) {
            return queryClient.getQueryData<R>([func.queryKey, ...args]);
        },
        isFetching<A extends any[], R>(func: QueryableFunction<A, Promise<R>>, ...args: A) {
            return queryClient.isFetching([func.queryKey, ...args]);
        },
    } as CustomQueryClient
}

export function useCustomQueryWithRefetch<A extends any[], R>(
    func: QueryableFunction<A, Promise<R>>,
    ...args: A
) {
    if (!func.queryKey) {
        throw new Error("useCustomQuery: no query key found");
    }

    const queryKey = [func.queryKey, ...args] as const;

    const query = useQuery<R, Error, R, typeof queryKey>(
        queryKey,
        (context) => func(...context.queryKey.slice(1) as A),
        func.options as any
    );

    const refetch = () => {
        query.refetch();
    };

    return { ...query, refetch, queryKey };
}

export function useCustomQueryWithRefetchSameQueryKey<A extends any[], R>(
    func: QueryableFunction<A, Promise<R>>,
    ...args: A
) {
    const queryClient = useQueryClient();

    if (!func.queryKey) {
        throw new Error("useCustomQuery: no query key found");
    }

    const generateQueryKey = (): string => {
        return func.queryKey || "";
    };

    const queryKey = [generateQueryKey(), ...args] as const;

    const query = useQuery<R, Error, R, typeof queryKey>(
        queryKey,
        (context) => func(...context.queryKey.slice(1) as A),
        func.options as any
    );

    const refetch = () => {
        queryClient.invalidateQueries(generateQueryKey());
    };

    return { ...query, refetch, queryKey };
}
