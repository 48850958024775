// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cClIzNyL4eVXV7aKThVi {\n  font-size: 1.5em;\n  font-weight: bold;\n  margin-right: 20px;\n}\n.aNAUmP0P85ognX6OZXbP {\n  background-color: #484644;\n  height: 48px;\n  color: white;\n  padding: 0 24px;\n}\n.fbseHcP1Nb6NJu3G9YpW {\n  align-items: center;\n  color: white;\n  display: flex;\n  justify-content: center;\n  overflow: hidden;\n}\n._rcwsxdktvuHm_3__ffh {\n  display: grid;\n  place-items: center;\n}\n.glKJDsLNFaLfhD00Nv6d {\n  color: white;\n  height: 100%;\n  cursor: pointer;\n}\n.glKJDsLNFaLfhD00Nv6d:hover {\n  background-color: #323130;\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTrackerV2/Banner/TrackerBanner.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEA;EACI,yBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAAJ;AAGA;EACI,mBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,mBAAA;AAFJ;AAKA;EACI,YAAA;EACA,YAAA;EACA,eAAA;AAHJ;AAKI;EACI,yBAAA;EACA,YAAA;AAHR","sourcesContent":[".logo {\n    font-size: 1.5em;\n    font-weight: bold;\n    margin-right: 20px;\n}\n\n.banner {\n    background-color: #484644;\n    height: 48px;\n    color: white;\n    padding: 0 24px;\n}\n\n.item {\n    align-items: center;\n    color: white;\n    display: flex;\n    justify-content: center;\n    overflow: hidden;\n}\n\n.centerDiv {\n    display: grid;\n    place-items: center;\n}\n\n.iconButton {\n    color: white;\n    height: 100%;\n    cursor: pointer;\n\n    &:hover {\n        background-color: #323130;\n        color: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "cClIzNyL4eVXV7aKThVi",
	"banner": "aNAUmP0P85ognX6OZXbP",
	"item": "fbseHcP1Nb6NJu3G9YpW",
	"centerDiv": "_rcwsxdktvuHm_3__ffh",
	"iconButton": "glKJDsLNFaLfhD00Nv6d"
};
export default ___CSS_LOADER_EXPORT___;
