import styles from "./TrackerNavigation.less";

import { INavLinkGroup, Stack, Nav, INavLink, INavStyles, Icon } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { Pages } from "../../models/Nav";
import { getTabCache } from "../../utils/pageUtils";
import { useGotoPage } from "../../hooks/useGotoPage";
import { LogComponent, LogTarget } from "../../models/LogModel";
import { trackEventCallback } from "../../utils/AppInsights";

const myProjectLinks: INavLinkGroup[] = [
    {
        links: [
            {
                name: "Virtualized Efficiency Tracker",
                icon: "cloud",
                key: `${Pages.EfficiencyTrackerV2}/${Pages.MyProjects}/${Pages.VirtualizedTracker}`,
                url: `${Pages.EfficiencyTrackerV2}/${Pages.MyProjects}/${Pages.VirtualizedTracker}`,
            },
            {
                name: "Baremetal Efficiency Tracker",
                icon: "server",
                key: `${Pages.EfficiencyTrackerV2}/${Pages.MyProjects}/${Pages.BaremetalTracker}`,
                url: `${Pages.EfficiencyTrackerV2}/${Pages.MyProjects}/${Pages.BaremetalTracker}`,
            },
            {
                name: "Big Bets Efficiency Tracker",
                icon: "rocket",
                key: `${Pages.EfficiencyTrackerV2}/${Pages.MyProjects}/${Pages.BigBetsTracker}`,
                url: `${Pages.EfficiencyTrackerV2}/${Pages.MyProjects}/${Pages.BigBetsTracker}`,
            },
        ],
    },
];
const allProjectLinks: INavLinkGroup[] = [
    {
        links: [
            {
                name: "Virtualized Efficiency Tracker",
                icon: "cloud",
                key: `${Pages.EfficiencyTrackerV2}/${Pages.AllProjects}/${Pages.VirtualizedTracker}`,
                url: `${Pages.EfficiencyTrackerV2}/${Pages.AllProjects}/${Pages.VirtualizedTracker}`,
            },
            {
                name: "Baremetal Efficiency Tracker",
                icon: "server",
                key: `${Pages.EfficiencyTrackerV2}/${Pages.AllProjects}/${Pages.BaremetalTracker}`,
                url: `${Pages.EfficiencyTrackerV2}/${Pages.AllProjects}/${Pages.BaremetalTracker}`,
            },
            {
                name: "Big Bets Efficiency Tracker",
                icon: "rocket",
                key: `${Pages.EfficiencyTrackerV2}/${Pages.AllProjects}/${Pages.BigBetsTracker}`,
                url: `${Pages.EfficiencyTrackerV2}/${Pages.AllProjects}/${Pages.BigBetsTracker}`,
            },
        ],
    },
];

const NavItemStyles: Partial<INavStyles> = {
    root: {
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #eee",
        overflowY: "auto",
    },
    groupContent: {
        marginBottom: 12,
    },
    linkText: {
        fontFamily: "Segoe UI",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
    },
    link: {
        paddingLeft: 16,
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const CollapseNavItemStyles: Partial<INavStyles> = {
    root: {
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #eee",
        overflowY: "auto",
    },
    groupContent: {
        marginBottom: 12,
    },
    linkText: { display: 'none' }, // hide text
    link: {
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "1px",
            "padding-left": "6px",
        },
    },
};

const TrackerNavigation: React.FC = () => {
    const location = useLocation();
    const gotoPage = useGotoPage();

    const [ collapse, setCollapse ] = useState<boolean>(false);

    const handleGlobalNavClick = () => {
        setCollapse(!collapse);
    };

    const handleClick = useCallback(
        (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
            if (item?.target !== "_blank") {
                ev?.preventDefault();
                if (item?.url) {
                    const tab = getTabCache(item.tabKey || item.url);
                    gotoPage(item.url + (tab ? `/${tab}` : ""));
                }
            }
            trackEventCallback(LogComponent.PivotNavPane, item?.logElement, item?.title || "", LogTarget.Nav);
        },
        [gotoPage]
    );

    return (
        <Stack className={collapse ? styles.navigationCollapse : styles.navigation} disableShrink>
            <Icon iconName="GlobalNavButton" className={styles.macLeftNav} onClick={handleGlobalNavClick} style={{ cursor: 'pointer' }}></Icon>
            {collapse && (<>
                <Nav selectedKey={location.pathname} styles={CollapseNavItemStyles} groups={myProjectLinks} onLinkClick={handleClick} />
                <Stack.Item shrink={0}><div className={styles.separator} /></Stack.Item>

                <Nav selectedKey={location.pathname} styles={CollapseNavItemStyles} groups={allProjectLinks} onLinkClick={handleClick} />
                <Stack.Item shrink={0}><div className={styles.separator} /></Stack.Item>
            </>)}
            {!collapse && (<>
                <Stack.Item shrink={0} className={styles.sectionHeader}>My Projects</Stack.Item>
                <Nav selectedKey={location.pathname} styles={NavItemStyles} groups={myProjectLinks} onLinkClick={handleClick} />
                <Stack.Item shrink={0}><div className={styles.separator} /></Stack.Item>

                <Stack.Item shrink={0} className={styles.sectionHeader}>All Projects</Stack.Item>
                <Nav selectedKey={location.pathname} styles={NavItemStyles} groups={allProjectLinks} onLinkClick={handleClick} />
                <Stack.Item shrink={0}><div className={styles.separator} /></Stack.Item>
            </>)}
        </Stack>
    );
};

export default TrackerNavigation;
