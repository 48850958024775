import styles from "./DiscussionCard.less";

import React from 'react';
import { format } from "date-fns";


interface CardProps {
    Owner: string;  //Discuss
    Discuss: string;  //Owner
    CreateTime: Date; //CreateTime
}

const DiscussionCard: React.FC<CardProps> = ({ Owner, CreateTime, Discuss}) => {
    const cardStyle = {
        borderLeftColor: "transparent",
        backgroundColor: "#ffffff",
    };

    return (
        <div className={styles.card} style={cardStyle}>
            <div className={styles.cardContent}>
                <div className={styles.owner}>
                    {Owner}
                </div>
                <div className={styles.discuss}>{Discuss}</div>
            </div>
            <div className={styles.cardTime}>
                <span style={{ textDecoration: 'underline' }}>
                    {format(new Date(CreateTime), 'M/d/yyyy HH:mm:ss')}
                </span>
            </div>
        </div>
    );
};

export default DiscussionCard;