const numberFormats = new Map<number, Intl.NumberFormat>([
    [2, new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 })]
]);

const lookup: [number, string][]  = [[1e12, "T"], [1e9, "B"], [1e6, "M"], [1e3, "K"]];

const scientific : [number, string][] = [[1e-3, "E-3"], [1e-4, "E-4"], [1e-5, "E-5"], [1e-6, "E-6"], [1e-7, "E-7"], [1e-8, "E-8"], [1e-9, "E-9"]];

export function formatNumber(num: number, maximumFractionDigits = 2): string {
    let numberFormat = numberFormats.get(maximumFractionDigits);
    if (!numberFormat) {
        numberFormat = new Intl.NumberFormat("en-US", { maximumFractionDigits });
        numberFormats.set(maximumFractionDigits, numberFormat);
    }
    return numberFormat.format(num);
}

// Format currency same as Below format: $25.11M
export function currencyFormatter(num: number, digits = 2, prefix = "$"): string {
    // Negative numbers.
    let negative = "";
    if (num < 0) {
        num = -num;
        negative = "-";
    }

    for (const unit of lookup) {
        if (num >= unit[0]) {
            return prefix + negative + formatNumber(num / unit[0], digits) + unit[1];
        }
    }

    // Extremly small number, scientific notation.
    if (num < 0.01 && num >= 1e-9)
    {
        for (const eUnit of scientific)
        {
            if (num >= eUnit[0])
            {
                return prefix + negative + formatNumber(num / eUnit[0], digits) + eUnit[1];
            }
        }
    }
    return prefix + negative + formatNumber(num, digits);
}

export function currencyFormatterNegativeFirst(num: number, digits = 2, prefix = "$"): string {
    // Negative numbers.
    let negative = "";
    if (num < 0) {
        num = -num;
        negative = "-";
    }

    for (const unit of lookup) {
        if (num >= unit[0]) {
            return negative + prefix + formatNumber(num / unit[0], digits) + unit[1];
        }
    }

    // Extremly small number, scientific notation.
    if (num < 0.01 && num >= 1e-9) {
        for (const eUnit of scientific) {
            if (num >= eUnit[0]) {
                return negative + prefix + formatNumber(num / eUnit[0], digits) + eUnit[1];
            }
        }
    }
    return negative + prefix + formatNumber(num, digits);
}