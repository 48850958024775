/** PCM Platform Models */
export const enum SubstratePlatformPivotEnum {
    Service = "Service",
    ProcessName = "ProcessName",
    AppName = "AppName",
    Client = "Client",
    Resource = "Resource",
    Ring = "Ring",
    SubApp = "SubApp",
    ClientComponent = "ClientComponent",
}

export const enum SubstratePlatformResourceTypeEnum {
    CPU = "CPU",
    Memory = "Memory",
    IO = "IO",
    HDDStorage = "HDDStorage",
    SSDStorage = "SSDStorage",
    Network = "Network",
}

/** Backend Total Cost Card */
export interface ISubstratePlatformBackendTotalCostCard {
    type: SubstratePlatformResourceTypeEnum;
    cost: number;
    costPre: number;
}

export interface ISubstratePlatformBackendServiceIdPivot {
    serviceId: string;
    pivotBy: string;
    pivotByItem: string;
    totalProcessCpuCores?: number;
    totalStoreCpuCores?: number;
    totalRestCpuCores?: number;
    totalMemoryGb?: number;
    totalIOCount?: number;
    totalHddStorageGb?: number;
    totalSsdStorageGb?: number;
    totalNetworkT0Gb?: number;
    totalNetworkT2Gb?: number;
    totalProcessCpuCost?: number;
    totalStoreCpuCost?: number;
    totalRestCpuCost?: number;
    totalMemoryCost?: number;
    totalIOCost?: number;
    totalHddCost?: number;
    totalSsdCost?: number;
    totalNetworkT0Cost?: number;
    totalNetworkT2Cost?: number;
}

/** Backend Total Cost Table Interface */
export interface ISubstratePlatformBackendTableItem {
    serviceId: string;
    pivotBy: string;
    pivotByItem: string;
    totalCost: number;
    totalProcessCpuCores: number;
    totalStoreCpuCores: number;
    totalRestCpuCores: number;
    totalMemoryGb: number;
    totalIOCount: number;
    totalHddStorageGb: number;
    totalSsdStorageGb: number;
    totalNetworkT0Gb: number;
    totalNetworkT2Gb: number;
    totalProcessCpuCost: number;
    totalStoreCpuCost: number;
    totalRestCpuCost: number;
    totalMemoryCost: number;
    totalIOCost: number;
    totalHddCost: number;
    totalSsdCost: number;
    totalNetworkT0Cost: number;
    totalNetworkT2Cost: number;
}

export function getSubstratePlatformPivotString(pivotEnum: SubstratePlatformPivotEnum): string {
    switch (pivotEnum) {
        case SubstratePlatformPivotEnum.Service:
            return "Service";
        case SubstratePlatformPivotEnum.ProcessName:
            return "Process Name";
        case SubstratePlatformPivotEnum.AppName:
            return "App Name";
        case SubstratePlatformPivotEnum.Client:
            return "Client";
        case SubstratePlatformPivotEnum.Resource:
            return "Resource";
        case SubstratePlatformPivotEnum.Ring:
            return "Ring";
        case SubstratePlatformPivotEnum.SubApp:
            return "Sub App";
        case SubstratePlatformPivotEnum.ClientComponent:
            return "Client Component";
    }
}

export function getSubstratePlatformResourceTypeString(pivotEnum: SubstratePlatformResourceTypeEnum): string {
    switch (pivotEnum) {
        case SubstratePlatformResourceTypeEnum.CPU:
            return "CPU";
        case SubstratePlatformResourceTypeEnum.Memory:
            return "Memory";
        case SubstratePlatformResourceTypeEnum.IO:
            return "IO";
        case SubstratePlatformResourceTypeEnum.HDDStorage:
            return "Storage HDD";
        case SubstratePlatformResourceTypeEnum.SSDStorage:
            return "Storage SSD";
        case SubstratePlatformResourceTypeEnum.Network:
            return "Network";
    }
}

/** Backend Total Cost Table item default */
export const DefaultPlatformBackendCostItem: ISubstratePlatformBackendTableItem = {
    serviceId: '',
    pivotBy: '',
    pivotByItem: '',
    totalCost: 0,
    totalProcessCpuCores: 0,
    totalStoreCpuCores: 0,
    totalRestCpuCores: 0,
    totalMemoryGb: 0,
    totalIOCount: 0,
    totalHddStorageGb: 0,
    totalSsdStorageGb: 0,
    totalNetworkT0Gb: 0,
    totalNetworkT2Gb: 0,
    totalProcessCpuCost: 0,
    totalStoreCpuCost: 0,
    totalRestCpuCost: 0,
    totalMemoryCost: 0,
    totalIOCost: 0,
    totalHddCost: 0,
    totalSsdCost: 0,
    totalNetworkT0Cost: 0,
    totalNetworkT2Cost: 0,
}