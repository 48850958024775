// Return mail2Person link
// Example input:   abc@microsoft.com;def@microsoft.com
import * as React from 'react';

interface IProps {
    users: string;
}

const Mail2Person: React.FC<IProps> = (props) => {
    const { users } = props;

    const formatMailTo = (users: string) => {
        const emailList = users.split(';').map(email => email.trim()).join(',');
        return `mailto:${emailList}`;
    };

    const formatUserNames = (users: string) => {
        return users.split(';')
            .map(email => email.split('@')[0].trim())
            .join('; ');
    };

    return (
        users !== 'null' ? (
            <a href={formatMailTo(users)}>{formatUserNames(users)}</a>
        ) : (<>null</>)
    );
};

export default Mail2Person;
