import { formatNumber } from "../../../../utils/currency";
import { Component, ComponentTypeEnum, TargetActualGapPair, EfficiencyTrackerReductionPlan, EfficiencyTrackerReductionPlanExtend, EfficiencyTrackerReductionPlanRegionConfig } from "../../../../models/EfficiencyTracker";

export function formatValue(value: unknown, digit?: number): string {
    if (typeof value === 'string') {
        return value;
    } else if (typeof value === 'number') {
        return formatNumber(value, digit);
    } else if (value instanceof Date) {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return value.toLocaleDateString('en-US', options);
    } else {
        return String(value);
    }
}

export function mapPlansToExtend(plans: EfficiencyTrackerReductionPlan[]): EfficiencyTrackerReductionPlanExtend[] {
    return plans.map((plan: EfficiencyTrackerReductionPlan) => {
        const extendedConfigs: EfficiencyTrackerReductionPlanRegionConfig[] = plan.EfficiencyTrackerReductionPlanRegionConfigs as unknown as EfficiencyTrackerReductionPlanRegionConfig[];
        const components: Component[] = plan.Components as unknown as Component[];

        return extendedConfigs.map((config) => ({
            Id: plan.Id,
            ProjectId: plan.ProjectId,
            Target: plan.Target,
            Status: plan.Status,
            ConfidenceLevel: plan.ConfidenceLevel,
            QualityForMap: plan.QualityForMap,
            Platform: plan.Platform,
            Owner: plan.Owner,
            CreateTime: new Date(plan.CreateTime),
            ResourceType: plan.ResourceType,
            EfficiencyTargetCategory: (plan.EfficiencyTargetCatagory == undefined || plan.EfficiencyTargetCatagory == null || plan.EfficiencyTargetCatagory == "") ? "Demands" : plan.EfficiencyTargetCatagory,
            BaseLineTime: new Date(plan.CreateTime),
            BaseLineValue: config.BaseLineValue ? config.BaseLineValue : "1",
            Region: config.Region ? config.Region : "",
            Targets: config.Targets,
            EffectivePeriod: new Date(config.EffectivePeriod),
            Process: getComponent(components, ComponentTypeEnum.Process),
            RestApp: getComponent(components, ComponentTypeEnum.RestApp),
            Shard: getComponent(components, ComponentTypeEnum.Shard),
            Forest: getComponent(components, ComponentTypeEnum.Forest),
            Dag: getComponent(components, ComponentTypeEnum.Dag),
            DagConfiguration: getComponent(components, ComponentTypeEnum.DagConfiguration),
            Sku: getComponent(components, ComponentTypeEnum.Sku),
            TrackingGap: plan.TrackingGap ? "Yes" : "No",
        }));
    }).flat();
}
export function getComponent(components: Component[], type: ComponentTypeEnum): string | undefined {
    const filteredValues = components
        .filter((item: Component) => item.Type === type)
        .map((item: Component) => `[ ${item.Value} ]`);

    if (filteredValues.length > 0) {
        return filteredValues.join('\n');
    } else {
        return undefined;
    }
}

export function mergeDownloadDataPair(
    actualData: [number, number][],
    targetData: [number, number][],
    gapData: [number, number][]
): TargetActualGapPair[] {
    const mergedData: TargetActualGapPair[] = [];

    // Merge the data while ensuring no duplicates
    const allTimes = new Set<number>([
        ...actualData.map(([time]) => time),
        ...targetData.map(([time]) => time),
        ...gapData.map(([time]) => time)
    ]);

    allTimes.forEach(timeValue => {
        const time = new Date(timeValue);
        const targetItem = targetData.find(([time]) => time === timeValue);
        const actualItem = actualData.find(([time]) => time === timeValue);
        const gapItem = gapData.find(([time]) => time === timeValue);

        const newDataPair: TargetActualGapPair = {
            Time: time,
            target: targetItem ? targetItem[1] : 0,
            actual: actualItem ? actualItem[1] : undefined,
            gap: gapItem ? gapItem[1] : undefined
        };

        mergedData.push(newDataPair);
    });

    return mergedData;
}

export const CompensateLocalDateToUTC = (date: Date): Date => {
    const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return utcDate;
};

export function checkIsDRI(DRI: string, UserName: string): boolean {
    const regex = /(\w+)@microsoft\.com/;
    const match = UserName.match(regex);

    if (match) {
        //const username = match[1]; //Fetch Prefix
        const names = DRI.split(';').map(name => name.trim());
        return names.includes(UserName);
    }

    return false;
}