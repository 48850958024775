import styles from './Table.less';

import React, { useCallback, useEffect, useState } from "react";
import { ActionButton, CheckboxVisibility, CommandBar, DetailsList, DetailsListLayoutMode, IColumn, IColumnReorderOptions, ICommandBarItemProps, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListStyles, IIconProps, IRenderFunction, SelectionMode, Separator, TextField, TooltipHost, mergeStyleSets } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { Selection } from '@fluentui/react/lib/Selection';
import { useLocation } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";

import { useGotoPage } from '../../../../../hooks/useGotoPage';
import { useEFTrackerBigBetsProject, useEFTrackerBigBetsLatestByProjectId } from '../../../../../hooks/useEfficiencyTrackerProject';
import { BigBetsMetrics, EFTrackerBigBetsLatestByProject, EFTrackerBigBetsProject } from '../../../../../models/EfficiencyTrackerBigBets';
import EmptyState from '../../../../common/state/EmptyState';
import LoadingState from '../../../../ResponseBoundary/LoadingState';
import EfficiencyStatus from '../../Tools/EfficiencyStatus';
import { EfficiencyStatusEnum } from '../../../../../models/EfficiencyTracker';
import { checkIsDRI, formatValue } from '../../Tools/ExportFunction';
import { Pages } from '../../../../../models/Nav';
import MoreVerticalsBigBets from '../Tools/MoreVerticalsBigBets';
import { currencyFormatter, currencyFormatterNegativeFirst } from '../../../../../utils/currency';
import WorkloadFilter from '../../../../common/FiltersBanner/WorkloadFilter/WorkloadFilter';
import { CategoryDivision, CategoryDivisionDisplayName } from '../../../../../models/CategoryDivision';
import FiltersDropDown from '../../../../common/FiltersBanner/FiltersDropDown/FiltersDropDown';
import { useCategoryFilters } from '../../../../../hooks/useFilters';
import { LogComponent, LogElement, LogTarget } from '../../../../../models/LogModel';
import { trackEventCallback } from '../../../../../utils/AppInsights';
import Mail2Person from '../../Tools/Mail2Person';


const searchIconProps: IIconProps = { iconName: 'Search', styles: { root: { color: 'blue' } } };

const columnKeys = {
    Index: 'Index',
    ProjectId: 'ProjectId',
    Category: 'Category',
    Workload: 'Workload',
    Name: 'Name',
    MoreVertical: 'MoreVertical',
    DRI: 'DRI',
    CVPSponsor: 'CVPSponsor',
    GEMGPM: 'GEMGPM',
    ImpactDollar: 'ImpactDollar',
    LatestImpact: 'LatestImpact',
    DeliveryETA: 'DeliveryETA',
    ConfidenceInImpact: 'ConfidenceInImpact',
    ExecutionStatus: 'ExecutionStatus',
    LatestNote: 'LatestNote',
    LinkToTicket: 'LinkToTicket',
    Notes: 'Notes',
    Owner: 'Owner',
    CreateTime: 'CreateTime',
    LastModifyTime: 'LastModifyTime',
};

const columns: IColumn[] = [
    {
        key: columnKeys.Index, name: 'Index', minWidth: 20, maxWidth: 60, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: columnKeys.Category, name: 'Category', minWidth: 20, maxWidth: 100, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: columnKeys.Workload, name: 'Workload', minWidth: 20, maxWidth: 100, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: columnKeys.Name, name: 'Name', minWidth: 30, maxWidth: 150, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: columnKeys.MoreVertical, name: '', minWidth: 15, maxWidth: 15, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.DRI, name: 'DRI', minWidth: 60, maxWidth: 80, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.CVPSponsor, name: 'CVP Sponsor', minWidth: 60, maxWidth: 100, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.GEMGPM, name: 'GEM/GPM', minWidth: 60, maxWidth: 80, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ImpactDollar, name: 'Target Impact', minWidth: 20, maxWidth: 100, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.LatestImpact, name: 'Latest Impact', minWidth: 20, maxWidth: 100, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.DeliveryETA, name: 'Delivery ETA', minWidth: 30, maxWidth: 80, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ConfidenceInImpact, name: 'Confidence', minWidth: 60, maxWidth: 80, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ExecutionStatus, name: 'Execution status', minWidth: 60, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.LatestNote, name: 'Latest Note', minWidth: 20, maxWidth: 150, isResizable: true, className: styles.nomarlColumn
    },
    // Temporary hiding
    //{
    //    key: columnKeys.LinkToTicket, name: 'Ticket', minWidth: 20, maxWidth: 40, isResizable: false, className: styles.nomarlColumn
    //},
    {
        key: columnKeys.Notes, name: 'Description', minWidth: 20, maxWidth: 150, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.CreateTime, name: 'Create Date', minWidth: 50, maxWidth: 80, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: columnKeys.LastModifyTime, name: 'Last Modify Date', minWidth: 50, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
];

const totalMaxWidth = columns.reduce((sum, column) => sum + (column.maxWidth || 0), 450);

const priorityMap: Record<string, number> = {
    "Category": 7,
    "Workload": 6,
    "Confidence": 5,
    "ExecutionStatus": 4,
    //Confidence
    "High": 3,
    "Medium": 2,
    "Low": 1
};

const SupportedFilters = [
    CategoryDivision.Category,
    CategoryDivision.Workload,
    CategoryDivision.Confidence,
    CategoryDivision.ExecutionStatus,
];

function isSearchableFilter(
    category: CategoryDivision,
) {
    switch (category) {
        case CategoryDivision.Category:
        case CategoryDivision.Workload:
            return true;
        case CategoryDivision.Confidence:
        case CategoryDivision.ExecutionStatus:
            return false;
        default:
            return false;
    }
}

interface IProjectDetailsTable {
    IsMyProject: boolean;
}

const BigBetsProjectTable: React.FC<IProjectDetailsTable> = (props) => {
    //Get location & account
    const gotoPage = useGotoPage();
    const { instance } = useMsal();
    const location = useLocation();
    const account = instance.getActiveAccount();

    //Get project & latest result
    const [allProject, setAllProject] = useState<EFTrackerBigBetsProject[]>([]);
    const [filteredProject, setFilteredProject] = useState<EFTrackerBigBetsProject[]>([]);
    const [showProject, setShowProject] = useState<EFTrackerBigBetsProject[]>([]);
    const [projectLatestResults, setProjectLatestResults] = useState<EFTrackerBigBetsLatestByProject[]>([]);
    const { projects: project, isLoading, refetch, isRefetching } = useEFTrackerBigBetsProject();
    const { results: latestPair, isLoading: isLoadingActuals, refetch: refetchActuals, isRefetching: isRefetchingAcutals } = useEFTrackerBigBetsLatestByProjectId(allProject.map(item => item.ProjectID));
    const [tableVisible, { setFalse: setTableVisibleDisable, setTrue: setTableVisibleEnable }] = useBoolean(false);

    // Refresh table
    const [refreshIndex, setRefreshIndex] = useState<number>(0);

    //Get Columns
    const [newColumns, setnewColumns] = useState<IColumn[]>(columns || []);

    //Select
    const [selectDismiss, { setFalse: setSelectDismissDone, setTrue: setSelectDismiss }] = useBoolean(false);
    const [selectionCount, setSelectionCount] = React.useState<string>('');
    const [totalImpact, setTotalImpact] = React.useState<number>(0);
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]); //For Download

    const [nameFilterString, setNameFilterString] = React.useState<string>('');

    //Global Filters
    const [selectedFilters, setSelectedFilters] = useState<CategoryDivision[]>([]);
    const { filters: globalFilters, clearAllFiltersOnly } = useCategoryFilters();

    /***** Global Filters ********************************/
    // Global fiter command bar item
    const filterBarItem: ICommandBarItemProps[] = [
        {
            key: 'WorkloadFilterProject',
            onRender: () => (
                <div className={styles.Filter}>
                    <FiltersDropDown
                        selectedKeys={selectedFilters}
                        onChange={setSelectedFilters}
                        supportedFilters={SupportedFilters}
                    />
                    <ActionButton key="clear" iconProps={{ iconName: "ClearFilter" }} onClick={onClearAll} style={{ padding: 0 }}>
                        Reset all
                    </ActionButton>
                    {selectedFilters.map((filter) => (
                        <WorkloadFilter
                            key={filter}
                            hidden={!selectedFilters.includes(filter)}
                            category={filter}
                            optionList={getFilterList(filter)}
                            displayName={CategoryDivisionDisplayName[filter]}
                            isSearchable={isSearchableFilter(filter)}
                        />
                    ))}
                </div>
            ),
        },
    ];

    // Get all fiter list for each CategoryDivision
    function getFilterList(
        category: CategoryDivision,
    ) {
        switch (category) {
            case CategoryDivision.Category:
                return Array.from(
                    new Set(allProject.map(item => item.Category.trim()))
                ).sort();
            case CategoryDivision.Workload:
                return Array.from(
                    new Set(allProject.map(item => item.Workload.trim()))
                ).sort();
            case CategoryDivision.Confidence:
                return [EfficiencyStatusEnum.High, EfficiencyStatusEnum.Medium, EfficiencyStatusEnum.Low];
            case CategoryDivision.ExecutionStatus:
                return [EfficiencyStatusEnum.OnTrack, EfficiencyStatusEnum.Delayed, EfficiencyStatusEnum.AtRisk];
            default:
                return [];
        }
    }

    //Get all used CategoryDivision
    function getSelectedFiltersFromGlobalFilter() {
        const filters = globalFilters.filters;
        const tempSelectedFilters = new Set<CategoryDivision>([]);
        SupportedFilters.forEach((filterKey) => {
            if ((filters[filterKey]?.length || 0) > 0) {
                tempSelectedFilters.add(filterKey);
            }
        });
        selectedFilters.forEach((item) => {
            tempSelectedFilters.add(item);
        });

        selectedFilters.sort((a: CategoryDivision, b: CategoryDivision) => {
            return priorityMap[a as string] > priorityMap[b as string] ? 1 : -1;
        });

        return tempSelectedFilters;
    }

    //Clear all Global Filters
    const onClearAll = useCallback(() => {
        clearAllFiltersOnly()
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.ClearAll, "Clear all", LogTarget.Button);
    }, [clearAllFiltersOnly]);

    /***** CommandBar Item ********************************/
    //Download format
    function escapeCSVValue(value: string): string {
        if (typeof value === 'string') {
            value = value.replace(/"/g, '""');
            if (value.includes(',') || value.includes('\n') || value.includes('\r') || value.includes('"') || value.includes('/') || value.includes('#')) {
                value = `"${value}"`;
            }
        }
        return value;
    }

    function convertItemToCSVRow(item: EFTrackerBigBetsProject): string {
        const result = projectLatestResults.find(result => result.ProjectID === item.ProjectID);
        const csvValues = [
            item.ProjectID,
            escapeCSVValue(item.Category),
            escapeCSVValue(item.Workload),
            escapeCSVValue(item.Name),
            escapeCSVValue(item.DRI),
            escapeCSVValue(item.Metrics ? (JSON.parse(item.Metrics) as unknown as BigBetsMetrics).CVPSponsor : "null"),
            escapeCSVValue(item.Metrics ? (JSON.parse(item.Metrics) as unknown as BigBetsMetrics).GEMGPM : "null"),
            item.ImpactDollar,
            result ? result.LatestActual : 0,
            item.DeliveryETA.toLocaleDateString(),
            item.ConfidenceInImpact,
            item.ExecutionStatus,
            (result !== undefined && result?.Discussion !== null) ? escapeCSVValue(result.Discussion.Note) : "null",
            escapeCSVValue(item.Notes),
            escapeCSVValue(item.LinksToExistingTicketItem),
            escapeCSVValue(item.Owner),
            item.CreateTime.toISOString(),
            item.LastModifyTime.toISOString(),
        ];

        return csvValues.join(',');
    }

    // Download 
    const _download = () => {
        const selectedItems = selectedIndices.map(index => showProject[index]);
        const customHeaders = [
            columnKeys.ProjectId,
            columnKeys.Category,
            columnKeys.Workload,
            columnKeys.Name,
            columnKeys.DRI,
            columnKeys.CVPSponsor,
            columnKeys.GEMGPM,
            columnKeys.ImpactDollar,
            columnKeys.LatestImpact,
            columnKeys.DeliveryETA,
            columnKeys.ConfidenceInImpact,
            columnKeys.ExecutionStatus,
            columnKeys.LatestNote,
            columnKeys.Notes,
            columnKeys.LinkToTicket,
            columnKeys.Owner,
            columnKeys.CreateTime,
            columnKeys.LastModifyTime,
        ];

        const csvHeader = customHeaders.join(',');
        const csvRows = selectedItems.map(item => convertItemToCSVRow(item));

        // Combine header and rows into CSV content
        const csvContent = [csvHeader, ...csvRows].join('\n');

        // Create a new Blob object for the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);

        // Set the download URL and filename
        link.setAttribute("href", url);
        link.setAttribute("download", `BigBets-SelectedProject.csv`);
        document.body.appendChild(link);

        // Programmatically click the link to trigger download
        link.click();

        // Clean up and revoke the object URL
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    //Far commandBar Items
    const farItems: ICommandBarItemProps[] = [
        {
            key: 'TotalImpact',
            text: "Total Impact " + currencyFormatter(totalImpact, 2, "$"),
            disabled: true,
        },
        {
            key: 'SelectProjectCount',
            text: selectionCount,
            disabled: true,
        },
    ];

    //Command Bar items
    const commandBarItem: ICommandBarItemProps[] = [
        {
            key: 'AddProject',
            text: 'Add project',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                const project = props.IsMyProject ? Pages.MyProjects : Pages.AllProjects;
                gotoPage(`${Pages.EfficiencyTrackerV2}/${project}/${Pages.BigBetsTracker}/${Pages.EFAddProjectPage}`)
            },
        },
        {
            key: 'DownloadProject',
            text: 'Export',
            iconProps: { iconName: 'Download' },
            onClick: () => _download(),
            disabled: selectedIndices.length == 0,
        },
        {
            key: 'RefreshProject',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {
                refetch();
                refetchActuals();
                setRefreshIndex(refreshIndex + 1);
            },
        },
        {
            key: 'FilterProject',
            onRender: () => (
                <TextField
                    className={styles.Filter}
                    iconProps={ searchIconProps }
                    placeholder="Filter by name"
                    value={nameFilterString}
                    onChange={(ev, text) => _onFilterByName(ev, text)}
                />
            ),
        },
    ];

    //Fiter By Name
    const _onFilterByName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string | undefined): void => {
        setNameFilterString(text || "");
        const tmpProject = text ? filteredProject.filter(i => {
            return i.Name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        }) : filteredProject;

        const impact = tmpProject.reduce((acc, item) => acc + (item.ImpactDollar || 0), 0);

        setShowProject(tmpProject);
        setSelectionCount(`Total ${tmpProject.length} projects`);
        setTotalImpact(impact);
    };


    /***** All Status Change ********************************/
    // Set Global filters and filterd project list.
    useEffect(() => {
        const filters = globalFilters.filters;
        const categoryList = filters.Category;
        const workloadList = filters.Workload;
        const ConfidenceList = filters.Confidence;
        const ExecutionStatusList = filters.ExecutionStatus;

        const tmpProjects = [...allProject];

        const tmpFilterProject = tmpProjects.filter(item => {
            const categoryFilter = categoryList?.length ? categoryList.includes(item.Category) : true;
            const workloadFilter = workloadList?.length ? workloadList.includes(item.Workload) : true;
            const ConfidenceFilter = ConfidenceList?.length ? ConfidenceList.includes(item.ConfidenceInImpact) : true;
            const ExecutionStatusFilter = ExecutionStatusList?.length ? ExecutionStatusList.includes(item.ExecutionStatus) : true;
            return categoryFilter && workloadFilter && ConfidenceFilter && ExecutionStatusFilter;
        });

        setSelectedFilters([...getSelectedFiltersFromGlobalFilter()]);
        setFilteredProject(tmpFilterProject);
        setNameFilterString("");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilters.filters, allProject, props.IsMyProject]);


    //Get latest result for each project
    useEffect(() => {
        if (isLoadingActuals || isRefetchingAcutals) {
            setProjectLatestResults([]);
            return;
        }
        if (!latestPair) return;

        setProjectLatestResults(latestPair);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingActuals, isRefetchingAcutals, allProject]);

    //Get project
    useEffect(() => {
        if (isLoading || isRefetching) {
            setAllProject([]);
            setFilteredProject([]);
            setTableVisibleDisable();
            return;
        }
        if (!project) return;
        const tmpProjects = project.filter(item => {
            const statusFilter = item.ExecutionStatus !== EfficiencyStatusEnum.Deleted.toString(); //exclude Delete plan.
            const isMyProjectFilter = props.IsMyProject ? checkIsDRI(item.DRI, account?.username || '') || item.Owner === account?.username : true;
            return statusFilter && isMyProjectFilter;
        });

        //Set project to table
        setAllProject(tmpProjects);
        setFilteredProject(tmpProjects);
        setTableVisibleEnable();
        setnewColumns(columns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account?.username, isLoading, isRefetching, props.IsMyProject]);

    //Update show project list
    useEffect(() => {
        setShowProject(filteredProject);
    }, [filteredProject]);

    //Update Impact & Count
    useEffect(() => {
        const impact = showProject.reduce((acc, item) => acc + (item.ImpactDollar || 0), 0);
        setTotalImpact(impact);
        setSelectionCount(`Total ${showProject.length} projects`);

        if (selectDismiss) {
            setSelectDismissDone();
        }
    }, [showProject, selectDismiss, setSelectDismissDone]);

    /***** DetailList ********************************/
    // DetailList Selection
    const _selection = new Selection({
        onSelectionChanged: () => {
            const selectedItems = _selection.getSelection() as EFTrackerBigBetsProject[];

            if (selectedItems.length > 0) { //Selected projects
                const impact = selectedItems.reduce((acc, item) => acc + (item.ImpactDollar || 0), 0);
                setTotalImpact(impact);
                setSelectionCount(_getSelectionDetails(_selection.getSelectedCount()));
            } else {
                setSelectDismiss();
            }

            setSelectedIndices(_selection.getSelectedIndices());
        },
    });

    const _getSelectionDetails = (count: number): string => {
        switch (count) {
            case 1:
                return 'Selected 1 project ';
            default:
                return `Selected ${count} projects `;
        }
    };

    // Reorder Column
    const _onColumnClick = (event?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        let sortedItems = [...showProject];
        if (!column || !column.key || column.key  === 'Index') return;
        let isSortedDescending = column.isSortedDescending;

        // If sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(sortedItems, column.key, isSortedDescending);

        setFilteredProject(sortedItems);
        setnewColumns(newColumns.map((col: IColumn) => {
            const newCol = { ...col };
            newCol.isSorted = newCol.key === column.key;

            if (newCol.isSorted) {
                newCol.isSortedDescending = isSortedDescending;
            }

            return newCol;
        }));
    };

    function _onItemInvoked(item: EFTrackerBigBetsProject): void {
        gotoPage(`${location.pathname}/${Pages.EFSubPage}/${item.ProjectID}`)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        if (columnKey ===  columnKeys.ConfidenceInImpact) {
            return [...items].sort((a: T, b: T) => {
                return ((isSortedDescending ? priorityMap[a[key] as string] < priorityMap[b[key] as string] : priorityMap[a[key] as string] > priorityMap[b[key] as string]) ? 1 : -1);
            });
        }

        return [...items].sort((a: T, b: T) => {
            return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
        });
    }

    // Handle column drag
    const _getColumnReorderOptions = (): IColumnReorderOptions => {
        return {
            handleColumnReorder: _handleColumnReorder,
        };
    }
    const  _handleColumnReorder = (draggedIndex: number, targetIndex: number):void => {
        const draggedItems = newColumns[draggedIndex];
        const reorderColumns: IColumn[] = [...newColumns];

        // insert before the dropped item
        reorderColumns.splice(draggedIndex, 1);
        reorderColumns.splice(targetIndex, 0, draggedItems);
        setnewColumns(reorderColumns);
    };

    function _renderItemColumn(item: EFTrackerBigBetsProject, index?: number, column?: IColumn): React.ReactNode {
        let fieldContent;
        const latestProject = projectLatestResults.find(result => result.ProjectID === item.ProjectID);
        switch (column?.key) {
            case columnKeys.CVPSponsor:
                fieldContent = item.Metrics ? (JSON.parse(item.Metrics) as unknown as BigBetsMetrics).CVPSponsor : "null";
                break
            case columnKeys.GEMGPM:
                fieldContent = item.Metrics ? (JSON.parse(item.Metrics) as unknown as BigBetsMetrics).GEMGPM : "null";
                break;
            case columnKeys.ImpactDollar:
                fieldContent = currencyFormatter(item.ImpactDollar, 2, "$");
                break;
            case columnKeys.LatestImpact:
                if (latestProject === undefined) {
                    fieldContent = "loading"
                } else {
                    const actual = latestProject.LatestActual;
                    if (actual === undefined) {
                        fieldContent = "0";
                    } else {
                        fieldContent = currencyFormatter(actual, 2, "$");
                    }
                }
                break;
            case columnKeys.LatestNote:
                if (latestProject === undefined) {
                    fieldContent = "loading"
                } else {
                    const dicussion = latestProject.Discussion;
                    if (dicussion === undefined || dicussion === null) {
                        fieldContent = "null";
                    } else {
                        fieldContent = dicussion.Note;
                    }
                }
                break;
            default:
                fieldContent = item[column?.key as keyof EFTrackerBigBetsProject];
        }

        switch (column?.key) {
            case columnKeys.Index:
                return <span>{formatValue((index || 0) + 1)}</span>;
            case columnKeys.Category:
            case columnKeys.Workload:
            case columnKeys.Name:
                return <span onClick={() => _onItemInvoked(item)} style={{ cursor: 'pointer' }}>{formatValue(fieldContent)}</span>;
            case columnKeys.MoreVertical:
                return <MoreVerticalsBigBets ProjectId={item.ProjectID} AllowDelete={item.Owner === account?.username} />;
            case columnKeys.ExecutionStatus:
                return <EfficiencyStatus Status={item.ExecutionStatus as EfficiencyStatusEnum} />;
            case columnKeys.ConfidenceInImpact:
                return <EfficiencyStatus Status={item.ConfidenceInImpact as EfficiencyStatusEnum} />;
            case columnKeys.LinkToTicket:
                return <a href={item.LinksToExistingTicketItem}>Link</a>;
            case columnKeys.DRI:
                return <Mail2Person users={item.DRI} />;
            case columnKeys.CVPSponsor:
                return <Mail2Person users={fieldContent as string} />;
            case columnKeys.GEMGPM:
                return <Mail2Person users={fieldContent as string} />;
            case columnKeys.ImpactDollar:
                return (
                    <div style={{ textAlign: "right", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <span>{fieldContent}</span>
                        {latestProject?.LastTarget !== undefined && latestProject.LastTarget !== 0 && item.ImpactDollar !== latestProject.LastTarget && (
                            <div className={item.ImpactDollar > latestProject.LastTarget ? styles.green : styles.red}>
                                <span>({currencyFormatterNegativeFirst(item.ImpactDollar - latestProject.LastTarget, 2, item.ImpactDollar > latestProject.LastTarget ? "+$" : "$")})</span>
                            </div>
                        )}
                       
                    </div>
                );
            case columnKeys.LatestImpact:
                return (
                    <div style={{ textAlign: "right" }}>
                        <span>{fieldContent}</span>
                    </div>
                );
            default:
                return <span>{formatValue(fieldContent)}</span>;
        }
    }

    const focusZoneProps = {
        className: styles.focusZone,
        'data-is-scrollable': 'true',
    } as React.HTMLAttributes<HTMLElement>;

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
            <TooltipHost {...tooltipHostProps} />
        );
        return defaultRender!({
            ...props,
            onRenderColumnHeaderTooltip,
        });
    };

    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            overflowX: 'auto',
            selectors: {
                '& [role=grid]': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    height: '80vh',
                    width: '100%',
                },
            },
        },
        headerWrapper: {
            flex: '0 0 auto',
        },
        contentWrapper: {
            flex: '1 1 auto',
            overflow: 'hidden',
        },
    };

    return (
        <div style={{ maxWidth: totalMaxWidth }}>
            <Separator styles={{ root: styles.Separator }} />
            <CommandBar
                styles={{
                    root: {
                        height: '32px',
                    },
                }}
                key={"CommandBar_filter"}
                items={filterBarItem}
            />
            <CommandBar
                key={"CommandBar_" + props.IsMyProject}
                items={commandBarItem}
                farItems={farItems}
            />
            {
                tableVisible ? (
                    allProject.length > 0 ?
                        <div style={{ flex: 1, overflow: 'hidden' }}>
                            <DetailsList
                                styles={gridStyles}
                                compact={false}
                                key={"DetailsList-" + props.IsMyProject + allProject.length + "-projectLatestResults:" + projectLatestResults + "-refreshIndex:" + refreshIndex}
                                items={showProject}
                                columns={newColumns}
                                onRenderItemColumn={_renderItemColumn}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="select row"
                                onColumnHeaderClick={_onColumnClick}
                                onItemInvoked={_onItemInvoked}
                                checkboxVisibility={CheckboxVisibility.always}
                                selection={_selection}
                                selectionMode={SelectionMode.multiple}
                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                focusZoneProps={focusZoneProps}
                                onRenderDetailsHeader={onRenderDetailsHeader}
                                selectionZoneProps={{
                                    disableAutoSelectOnInputElements: true,
                                    isSelectedOnFocus: false,
                                    className: styles.selectionZone,
                                }}
                                columnReorderOptions={_getColumnReorderOptions()}
                            />
                        </div> :
                        <div className={styles.empty}>
                            <div>
                                <EmptyState />
                            </div>
                            <p>No current projects. Please add project to see data.</p>
                        </div>
                ) : <LoadingState />
            }
            
        </div>
    );
};

export default BigBetsProjectTable;