/** Renders the form for creating a new efficiency project, including:
 * - Category
 * - Project name
 * - DRI
 * - Confidence
 * - ExecutionStatus
 * - Notes
 * - Link to Ticket
 */
import React from 'react';
import styles from "./Forms.less";

import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Updater } from 'use-immer';
import { Label } from '@fluentui/react/lib/Label';
import { IconButton } from '@fluentui/react';

import { AddProjectTypeEnum, EfficiencyStatusEnum, IErrorDict } from '../../../../../../models/EfficiencyTracker';
import { EFTrackerBigBetsProjectForm } from '../../../../../../models/EfficiencyTrackerBigBets';

interface IProps {
    formData: EFTrackerBigBetsProjectForm;
    updateFormData: Updater<EFTrackerBigBetsProjectForm>;
    errorDict: IErrorDict;
    updateErrorDict: Updater<IErrorDict>;
    mode: AddProjectTypeEnum;
}

const titleDictionary: Partial<Record<AddProjectTypeEnum, string>> = {
    [AddProjectTypeEnum.AddProject]: "New Big Bets Project",
    [AddProjectTypeEnum.EditProject]: "Edit Big Bets Project",
};


const BigBetsProjectInfo = ({ formData, updateFormData, errorDict, updateErrorDict, mode }: IProps) => {
    return (
        <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 554 } }} >

            <div className={styles.formTitle}>{titleDictionary[mode]}</div>

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Category</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="Category for your Big Bets project (e.g., Compute, Storage, CosmosDB)"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="Category for your Big Bets project"
                value={formData.Category}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("CategoryError" in errorDict) {
                            delete errorDict.CategoryError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Category = newValue ? newValue : "";
                    });
                }}
                errorMessage={("CategoryError" in errorDict) ? errorDict.CategoryError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Workload</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="Workload for your Big Bets project"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="Workload for your Big Bets project"
                value={formData.Workload}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("WorkloadError" in errorDict) {
                            delete errorDict.WorkloadError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Workload = newValue ? newValue : "";
                    });
                }}
                errorMessage={("WorkloadError" in errorDict) ? errorDict.WorkloadError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Name</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="Name for your Big Bets project"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="New project"
                value={formData.Name}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("ProjectNameError" in errorDict) {
                            delete errorDict.ProjectNameError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Name = newValue ? newValue : "";
                    });
                }}
                errorMessage={("ProjectNameError" in errorDict) ? errorDict.ProjectNameError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>DRI</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title='DRI(alias@microsoft.com) for your Big Bets project, sprit with";"'
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="Alice@microsoft.com;Bob@microsoft.com(Alias@microsoft.com)"
                value={formData.DRI}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("DriError" in errorDict) {
                            delete errorDict.DriError;
                        }
                    });
                }}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.DRI = newValue ? newValue : "";
                    });
                }}
                errorMessage={("DriError" in errorDict) ? errorDict.DriError : ""}
            />

            <Dropdown
                placeholder="Select a confidence level"
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Confidence</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="Describe your confidence on this project"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                selectedKey={formData.Confidence}
                options={[
                    { key: EfficiencyStatusEnum.Low, text: 'Low', title: 'May change the plan later' },
                    { key: EfficiencyStatusEnum.Medium, text: 'Medium', title: 'I can do it' },
                    { key: EfficiencyStatusEnum.High, text: 'High', title: 'No problem at all!' },
                ]}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("ConfidenceLevelError" in errorDict) {
                            delete errorDict.ConfidenceLevelError;
                        }
                    });
                }}
                onChange={(_, option) => {
                    updateFormData(formData => {
                        formData.Confidence = option ? option.key as string : "";
                    });
                }}
                errorMessage={("ConfidenceLevelError" in errorDict) ? errorDict.ConfidenceLevelError : ""}
            />

            <Dropdown
                placeholder="Select a Execution status"
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Execution status</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="Select the execution status on this project"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                selectedKey={formData.Status}
                options={[
                    { key: EfficiencyStatusEnum.OnTrack, text: EfficiencyStatusEnum.OnTrack },
                    { key: EfficiencyStatusEnum.Delayed, text: EfficiencyStatusEnum.Delayed },
                    { key: EfficiencyStatusEnum.AtRisk, text: EfficiencyStatusEnum.AtRisk },
                ]}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("ExecutionStatusError" in errorDict) {
                            delete errorDict.ExecutionStatusError;
                        }
                    });
                }}
                onChange={(_, option) => {
                    updateFormData(formData => {
                        formData.Status = option ? option.key as string : "";
                    });
                }}
                errorMessage={("ExecutionStatusError" in errorDict) ? errorDict.ExecutionStatusError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>Description</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title='Add the description for the project.'
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="Enter a description for your new Big Bets project"
                multiline rows={4}
                value={formData.Notes}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.Notes = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("NotesError" in errorDict) {
                            delete errorDict.NotesError;
                        }
                    });
                }}
                errorMessage={("NotesError" in errorDict) ? errorDict.NotesError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>CVP Sponsor</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title='Add the CVP Sponsor for the project, like: Alias@microsoft.com, sprit with";"'
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="Enter the CVP Sponsor: Alias@microsoft.com"
                value={formData.CVPSponsor}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.CVPSponsor = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("CVPSponsorError" in errorDict) {
                            delete errorDict.CVPSponsorError;
                        }
                    });
                }}
                errorMessage={("CVPSponsorError" in errorDict) ? errorDict.CVPSponsorError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>GEM/GPM</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title='Add the GEM/GPM for the project, like: Alias@microsoft.com, sprit with";"'
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="Enter the GEM/GPM: Alias@microsoft.com"
                value={formData.GEMGPM}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.GEMGPM = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("GEMGPMError" in errorDict) {
                            delete errorDict.GEMGPMError;
                        }
                    });
                }}
                errorMessage={("GEMGPMError" in errorDict) ? errorDict.GEMGPMError : ""}
            />

            <TextField
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }}>Link to Ticket</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title='Add Link to Ticket for the project. Temporary hiding.'
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                placeholder="https://ticketing.example.com/ticket/12345"
                value={formData.LinkToTicket}
                onChange={(_, newValue) => {
                    updateFormData(formData => {
                        formData.LinkToTicket = newValue ? newValue : "";
                    });
                }}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("TicketError" in errorDict) {
                            delete errorDict.TicketError;
                        }
                    });
                }}
                errorMessage={("TicketError" in errorDict) ? errorDict.TicketError : ""}
            />
        </Stack>
    );
}

export default BigBetsProjectInfo;