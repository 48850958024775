import { Cell } from "react-table";
import React from "react";
import substrateStyles from "./SubstratePlatformBackendTable.less";
import { DefaultPlatformBackendCostItem, ISubstratePlatformBackendTableItem, SubstratePlatformPivotEnum } from "../../../models/SubstratePlatform";
import { ReactTableColumnOption, ReactTableExpandableData } from "../../common/table/TableUtils";
import { TableColumnRenderer } from "../../common/table/TableColumnRenderer";
import { formatNumber } from "../../../utils/currency";
import { cloneDeep } from "lodash";

type TableColumnId =
    | keyof ISubstratePlatformBackendTableItem
    | "service";

const TableDisplayNames: Partial<Record<TableColumnId, string>> = {
    service: 'Service',
    pivotBy: 'Pivot By',
    pivotByItem: 'Pivot By Item',
    totalCost: 'Total Cost',
    totalProcessCpuCores: 'Total Process Cpu Cores',
    totalStoreCpuCores: 'Total Store Cpu Cores',
    totalRestCpuCores: 'Total Rest Cpu Cores',
    totalMemoryGb: 'Total Memory (GB)',
    totalIOCount: 'Total IO Count',
    totalHddStorageGb: 'Total Hdd Storage (GB)',
    totalSsdStorageGb: 'Total Ssd Storage (GB)',
    totalNetworkT0Gb: 'Total Network T0 (GB)',
    totalNetworkT2Gb: 'Total Network T2 (GB)',
    totalProcessCpuCost: 'Total Process Cpu Cost',
    totalStoreCpuCost: 'Total Store Cpu Cost',
    totalRestCpuCost: 'Total Rest Cpu Cost',
    totalMemoryCost: 'Total Memory Cost',
    totalIOCost: 'Total IO Cost',
    totalHddCost: 'Total Hdd Cost',
    totalSsdCost: 'Total Ssd Cost',
    totalNetworkT0Cost: 'Total Network T0 Cost',
    totalNetworkT2Cost: 'Total Network T2 Cost',
};

const TablePivotByColumnIds: Record<SubstratePlatformPivotEnum, TableColumnId[]> = {
    [SubstratePlatformPivotEnum.Service]: [
        "service",
        "totalProcessCpuCost", "totalStoreCpuCost", "totalRestCpuCost", "totalMemoryCost", "totalIOCost",
        "totalNetworkT0Cost", "totalNetworkT2Cost", "totalHddCost", "totalSsdCost", "totalCost",
        "totalProcessCpuCores", "totalStoreCpuCores", "totalRestCpuCores", "totalMemoryGb", "totalIOCount",
        "totalNetworkT0Gb", "totalNetworkT2Gb", "totalHddStorageGb", "totalSsdStorageGb"
    ],
    [SubstratePlatformPivotEnum.Resource]: [
        "service",
        "totalProcessCpuCost", "totalStoreCpuCost", "totalRestCpuCost", "totalMemoryCost", "totalIOCost",
        "totalNetworkT0Cost", "totalNetworkT2Cost", "totalHddCost", "totalSsdCost", "totalCost",
        "totalProcessCpuCores", "totalStoreCpuCores", "totalRestCpuCores", "totalMemoryGb", "totalIOCount",
        "totalNetworkT0Gb", "totalNetworkT2Gb", "totalHddStorageGb", "totalSsdStorageGb"
    ],
    [SubstratePlatformPivotEnum.Ring]: [
        "service",
        "totalProcessCpuCost", "totalStoreCpuCost", "totalRestCpuCost", "totalMemoryCost", "totalIOCost", "totalHddCost", "totalSsdCost", "totalCost",
        "totalProcessCpuCores", "totalStoreCpuCores", "totalRestCpuCores", "totalMemoryGb", "totalIOCount", "totalHddStorageGb", "totalSsdStorageGb"
    ],
    [SubstratePlatformPivotEnum.ProcessName]: [
        "service",
        "totalProcessCpuCost", "totalMemoryCost", "totalNetworkT0Cost", "totalNetworkT2Cost", "totalCost",
        "totalProcessCpuCores", "totalMemoryGb", "totalNetworkT0Gb", "totalNetworkT2Gb"
    ],
    [SubstratePlatformPivotEnum.AppName]: [
        "service",
        "totalRestCpuCost", "totalIOCost", "totalCost",
        "totalRestCpuCores", "totalIOCount"
    ],
    [SubstratePlatformPivotEnum.SubApp]: [
        "service",
        "totalRestCpuCost", "totalIOCost", "totalCost",
        "totalRestCpuCores", "totalIOCount"
    ],
    [SubstratePlatformPivotEnum.Client]: [
        "service",
        "totalStoreCpuCost", "totalIOCost", "totalCost",
        "totalStoreCpuCores", "totalIOCount"
    ],
    [SubstratePlatformPivotEnum.ClientComponent]: [
        "service",
        "totalStoreCpuCost", "totalIOCost", "totalCost",
        "totalStoreCpuCores", "totalIOCount"
    ],
};

const TablePivotByTotalCostDescription: Record<SubstratePlatformPivotEnum, string> = {
    [SubstratePlatformPivotEnum.Service]: "This total Cost equals total (CPU + Memory + IO + Hdd + Ssd) Cost.",
    [SubstratePlatformPivotEnum.Resource]: "This total Cost equals total (CPU + Memory + IO + Hdd + Ssd) Cost.",
    [SubstratePlatformPivotEnum.Ring]: "This total Cost equals total (CPU + Memory + IO + Hdd + Ssd) Cost.",
    [SubstratePlatformPivotEnum.ProcessName]: "This total Cost equals total (CPU + Memory) Cost.",
    [SubstratePlatformPivotEnum.AppName]: "This total Cost equals total (Memory + IO) Cost.",
    [SubstratePlatformPivotEnum.SubApp]: "This total Cost equals total (Memory + IO) Cost.",
    [SubstratePlatformPivotEnum.Client]: "This total Cost equals total (Memory + IO) Cost.",
    [SubstratePlatformPivotEnum.ClientComponent]: "This total Cost equals total (Memory + IO) Cost.",
};

const TableColumnMetricDescription: Partial<Record<TableColumnId, string>> = {
    totalProcessCpuCores: 'The average Process Cpu Cores over the defined period of time',
    totalStoreCpuCores: 'The average Store Cpu Cores over the defined period of time',
    totalRestCpuCores: 'The average Rest Cpu Cores over the defined period of time',
    totalMemoryGb: 'The average GB of Memory over the defined period of time',
    totalIOCount: 'The total IO Count over the defined period of time',
    totalHddStorageGb: 'The average GB of Hdd Storage over the defined period of time',
    totalSsdStorageGb: 'The average GB of Ssd Storage over the defined period of time',
    totalNetworkT0Gb: 'The average GB of Network T0 over the defined period of time',
    totalNetworkT2Gb: 'The average GB of Network T2 over the defined period of time',
};

export const SubstratePlatformBackendTableSubRowsPlaceHolder: ReactTableExpandableData<ISubstratePlatformBackendTableItem>[] = [
    {
        data: DefaultPlatformBackendCostItem,
        isSubRowPlaceHolder: true,
        depth: 1,
    },
];

function getDefaultColumnConfig(
    key: TableColumnId,
): ReactTableColumnOption<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, TableColumnId> {
    return {
        id: key,
        sortType: 'basic',
        name: TableDisplayNames[key] || "-",
        Footer: "-",
        sortDescFirst: true,
    };
}

function getNumericColumnConfig(
    key: TableColumnId,
): ReactTableColumnOption<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, TableColumnId> {
    return {
        id: key,
        sortType: (rowA, rowB, id, desc) => {
            const valueA = rowA.values[id];
            const valueB = rowB.values[id];
            if (desc == true) {
                if (typeof valueA != "number" && typeof valueB != "number") return 0;
                if (typeof valueA != "number" && typeof valueB == "number") return -1;
                if (typeof valueA == "number" && typeof valueB != "number") return 1;
            } else {
                if (typeof valueA != "number" && typeof valueB != "number") return 0;
                if (typeof valueA != "number" && typeof valueB == "number") return 1;
                if (typeof valueA == "number" && typeof valueB != "number") return -1;
            }
            const a = Number.parseFloat(rowA.values[id]);
            const b = Number.parseFloat(rowB.values[id]);
            if (a > b) return 1;
            if (b > a) return -1;
            return 0;
        },
        name: TableDisplayNames[key] || "-",
        Footer: "-",
        sortDescFirst: true,
    };
}

export function getSubstratePlatformBackendTableColumns(
    data: ISubstratePlatformBackendTableItem[],
    pivotBy: SubstratePlatformPivotEnum,
    serviceCount: number,
): ReactTableColumnOption<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, TableColumnId>[] {
    const totalCost = sumCosts(data);

    const allColumns: ReactTableColumnOption<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, TableColumnId>[] = [
        {
            ...getNumericColumnConfig("totalCost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalCost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            headerTooltip: (
                <div>{TablePivotByTotalCostDescription[pivotBy]}</div>
            ),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalCost),
        },
        {
            ...getNumericColumnConfig("totalProcessCpuCores"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalProcessCpuCores,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalProcessCpuCores"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalProcessCpuCores),
        },
        {
            ...getNumericColumnConfig("totalStoreCpuCores"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalStoreCpuCores,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalStoreCpuCores"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalStoreCpuCores),
        },
        {
            ...getNumericColumnConfig("totalRestCpuCores"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalRestCpuCores,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalRestCpuCores"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalRestCpuCores),
        },
        {
            ...getNumericColumnConfig("totalMemoryGb"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalMemoryGb,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalMemoryGb"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalMemoryGb),
        },
        {
            ...getNumericColumnConfig("totalIOCount"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalIOCount,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalIOCount"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalIOCount),
        },
        {
            ...getNumericColumnConfig("totalHddStorageGb"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalHddStorageGb,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalHddStorageGb"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalHddStorageGb),
        },
        {
            ...getNumericColumnConfig("totalSsdStorageGb"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalSsdStorageGb,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalSsdStorageGb"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalSsdStorageGb),
        },
        {
            ...getNumericColumnConfig("totalNetworkT0Gb"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalNetworkT0Gb,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalNetworkT0Gb"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalNetworkT0Gb),
        },
        {
            ...getNumericColumnConfig("totalNetworkT2Gb"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalNetworkT2Gb,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderNumber(cell.value),
            headerTooltip: (
                <div>{TableColumnMetricDescription["totalNetworkT2Gb"]}</div>
            ),
            Footer: TableColumnRenderer.renderNumber(totalCost?.totalNetworkT2Gb),
        },
        {
            ...getNumericColumnConfig("totalProcessCpuCost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalProcessCpuCost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalProcessCpuCost),
        },
        {
            ...getNumericColumnConfig("totalStoreCpuCost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalStoreCpuCost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalStoreCpuCost),
        },
        {
            ...getNumericColumnConfig("totalRestCpuCost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalRestCpuCost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalRestCpuCost),
        },
        {
            ...getNumericColumnConfig("totalMemoryCost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalMemoryCost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalMemoryCost),
        },
        {
            ...getNumericColumnConfig("totalIOCost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalIOCost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalIOCost),
        },
        {
            ...getNumericColumnConfig("totalHddCost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalHddCost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalHddCost),
        },
        {
            ...getNumericColumnConfig("totalSsdCost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalSsdCost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalSsdCost),
        },
        {
            ...getNumericColumnConfig("totalNetworkT0Cost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalNetworkT0Cost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalNetworkT0Cost),
        },
        {
            ...getNumericColumnConfig("totalNetworkT2Cost"),
            accessor: ({ data }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => data.totalNetworkT2Cost,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.totalNetworkT2Cost),
        },
        {
            ...getDefaultColumnConfig("service"),
            accessor: ({ data, depth }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>) => {
                let name: string | undefined;

                if (depth === 0 || pivotBy === SubstratePlatformPivotEnum.Resource || pivotBy === SubstratePlatformPivotEnum.Service) {
                    name = data.serviceId;
                } else {
                    name = data.pivotByItem || "Others";
                }

                return name || "-";
            },
            disableHide: true,
            Cell: (cell: Cell<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, string>) => {
                return <span title={cell.value}>{cell.value}</span>;
            },
            Footer: `Total - ${formatNumber(serviceCount)} Service${serviceCount > 1 ? "s" : ""}`,
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
        },
    ];

    const pivotBycolumnKeys = TablePivotByColumnIds[pivotBy];

    const result: ReactTableColumnOption<ReactTableExpandableData<ISubstratePlatformBackendTableItem>, TableColumnId>[] = [];

    pivotBycolumnKeys.map((key) => {
        const targetColumn = allColumns.find((col) => col.id === key);

        if (targetColumn) {
            result.push(targetColumn);
        }
    });

    return result;
}

export function sumCosts(costs?: ISubstratePlatformBackendTableItem[]): ISubstratePlatformBackendTableItem {
    const result: ISubstratePlatformBackendTableItem = cloneDeep(DefaultPlatformBackendCostItem);

    if (costs) {
        for (const item of costs) {
            result.totalCost += item.totalCost;
            result.totalProcessCpuCores += item.totalProcessCpuCores;
            result.totalStoreCpuCores += item.totalStoreCpuCores;
            result.totalRestCpuCores += item.totalRestCpuCores;
            result.totalMemoryGb += item.totalMemoryGb;
            result.totalIOCount += item.totalIOCount;
            result.totalHddStorageGb += item.totalHddStorageGb;
            result.totalSsdStorageGb += item.totalSsdStorageGb;
            result.totalNetworkT0Gb += item.totalNetworkT0Gb;
            result.totalNetworkT2Gb += item.totalNetworkT2Gb;
            result.totalProcessCpuCost = (result.totalProcessCpuCost || 0) + (item.totalProcessCpuCost || 0);
            result.totalStoreCpuCost = (result.totalStoreCpuCost || 0) + (item.totalStoreCpuCost || 0);
            result.totalRestCpuCost = (result.totalRestCpuCost || 0) + (item.totalRestCpuCost || 0);
            result.totalMemoryCost = (result.totalMemoryCost || 0) + (item.totalMemoryCost || 0);
            result.totalIOCost = (result.totalIOCost || 0) + (item.totalIOCost || 0);
            result.totalHddCost = (result.totalHddCost || 0) + (item.totalHddCost || 0);
            result.totalSsdCost = (result.totalSsdCost || 0) + (item.totalSsdCost || 0);
            result.totalNetworkT0Cost += item.totalNetworkT0Cost;
            result.totalNetworkT2Cost += item.totalNetworkT2Cost;
        }
    }

    return result;
}